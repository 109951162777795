import React from 'react';
import Lottie from 'react-lottie';
import animationData1 from './InstantObservability.json';


class MyComponent extends React.Component {
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: animationData1,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return <Lottie options={defaultOptions} height={400} width={400} />;
  }
}

export default MyComponent;